import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { QUERY } from "@/core/services/store/request.module";
import JwtService from "@/core/services/jwt.service";
import UnlockFolderDialog from "@/view/components/UnlockFolderDialog";
import ShowValue from "@/view/components/ShowValue";
import { EventBus } from "@/core/event-bus/event.bus";
import ObjectPath from "object-path";
import { AES } from "crypto-js";
export default {
  props: {
    label: {
      type: String,
      default: null
    },
    endpoint: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      loading: false,
      selected: null,
      rows: [],
      folder_unlock: {},
      folder_unlock_dialog: false
    };
  },
  methods: {
    folder_unlock_success: function folder_unlock_success(row) {
      var _this = this;

      this.folder_unlock = new Object({});
      this.folder_unlock_dialog = false;
      this.$nextTick(function () {
        var folder_id = AES.encrypt(JSON.stringify(row.id), _this.$enc_key).toString();
        var folder_uuid = AES.encrypt(JSON.stringify(row.uuid), _this.$enc_key).toString();

        _this.$router.push({
          name: _this.endpoint,
          query: {
            t: new Date().getTime(),
            folder: folder_id,
            fuuid: folder_uuid
          }
        });
      });
    },
    open_folder: function open_folder(row) {
      var _this2 = this;

      if (this.endpoint == "bin") {
        return false;
      }

      if (this.loading) {
        return false;
      }

      if (row.locked) {
        this.folder_unlock = row;
        this.folder_unlock_dialog = true;
        return false;
      }

      var endpoint = this.endpoint;

      if (this.endpoint == "starred") {
        endpoint = "mine";
      }

      if (row.type == "folder") {
        this.filter["page-no"] = 1;
        var folder_id = AES.encrypt(JSON.stringify(row.id), this.$enc_key).toString();
        var folder_uuid = AES.encrypt(JSON.stringify(row.uuid), this.$enc_key).toString();
        this.$router.push({
          name: endpoint,
          query: {
            t: new Date().getTime(),
            folder: folder_id,
            fuuid: folder_uuid
          }
        });
      } else {
        this.selected = row.uuid;
        this.$nextTick(function () {
          _this2.download_file();
        });
      }
    },
    download_file: function download_file() {
      if (this.endpoint == "bin") {
        return false;
      }

      var token = JwtService.getToken();
      var url = "".concat(this.$apiURL, "files/").concat(this.selected, "/download?token=").concat(token);
      window.open(url, "_blank");
    },
    get_rows: function get_rows() {
      var _this3 = this;

      /* http://127.0.0.1:8000/api/files/recent?linked-id=0&page-no=1&page-limit=50&t=1664779617123 */
      this.filter = {
        "linked-id": 0,
        "page-no": 1,
        "page-limit": 10,
        t: new Date().getTime()
      };
      this.loading = true;
      this.$store.dispatch(QUERY, {
        url: "files/".concat(this.endpoint),
        data: this.filter
      }).then(function (response) {
        _this3.rows = ObjectPath.get(response, "rows", []);
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this3.loading = false;
      });
    }
  },
  components: {
    "show-value": ShowValue,
    "unlock-folder-dialog": UnlockFolderDialog
  },
  mounted: function mounted() {
    var _this4 = this;

    this.get_rows();
    EventBus.$on("refresh-listing", function () {
      _this4.$nextTick(function () {
        _this4.get_rows();
      });
    });
  }
};