import _objectSpread from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _typeof from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DashboardListing from "@/view/pages/Dashboard-Listing";
import { EventBus } from "@/core/event-bus/event.bus";
import ObjectPath from "object-path";
import { mapGetters } from "vuex";
import { QUERY } from "@/core/services/store/request.module";
import { SET_UPLOAD_STATUS } from "@/core/services/store/common.module";
export default {
  name: "dashboard",
  data: function data() {
    return {
      right_bar: false,
      model: false,
      open_timeout: null,
      open_timeout_limit: 500,
      file_log: []
    };
  },
  methods: {
    redirect_ip: function redirect_ip(ip) {
      var url = "https://whatismyipaddress.com/ip/".concat(ip);
      window.open(url, "_blank");
    },
    get_logs: function get_logs() {
      var _this = this;

      this.$store.dispatch(QUERY, {
        url: "activity-log"
      }).then(function (response) {
        _this.file_log = response;
      }).catch(function (error) {
        console.log({
          error: error
        });
      });
    },
    do_action: function do_action(type) {
      EventBus.$emit(type, true);
    },
    site_logo: function site_logo() {
      var logoObject = this.layoutConfig("self.logo");
      var logo;

      if (typeof logoObject === "string") {
        logo = logoObject;
      }

      if (_typeof(logoObject) === "object") {
        logo = ObjectPath.get(logoObject, "light");
      }

      if (typeof logo === "undefined") {
        var logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }

      return process.env.BASE_URL + logo;
    }
  },
  components: {
    "dashboard-listing": DashboardListing
  },
  mounted: function mounted() {
    var _this2 = this;

    setTimeout(function () {
      _this2.$store.dispatch(SET_UPLOAD_STATUS, true);
    }, 500);
  },
  beforeDestroy: function beforeDestroy() {
    this.$store.dispatch(SET_UPLOAD_STATUS, false);
  },
  computed: _objectSpread({}, mapGetters(["layoutConfig"]))
};